import React from 'react';
// import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Headroom from 'headroom.js';
// import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
// import intl from 'react-intl-universal';
import { setCompany } from 'store/actions/comp';
import { Collapse, UncontrolledCollapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, NavItem, Nav, Container, UncontrolledTooltip, } from 'reactstrap';
import Link from 'components/Link/Link';
import { Image, Select, Button } from 'semantic-ui-react';

import { logout } from 'store/actions/auth';
import DropdownLang1 from './DropdownLang1';
// import ChgCompany from '../admin/ChgCompany';
import Parse from 'widget/parse';

const hostname = (process.env.BROWSER && global && global.window && global.window.location.hostname) || '';

const ColorNavbar = (props = {}) => {
  // const [navbarColor, setNavbarColor] = React.useState('navbar-transparent');
  const [navbarColor, setNavbarColor] = React.useState('');
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [data1, setData1] = React.useState([]);
  // const [data2, setData2] = React.useState([]);


  const {
    history,
    cookies = {},
    companyObj = {},
    user = {},
    webnavArr = [],
    companies = [],
    changeCompany,
  } = props;
  // const dbLang = (cookies && cookies.get('dbLang')) || 'name';
  const dbLang = 'name';
  // const logout = () => {
  // const toUrl = companyObj.useOfficial ? "/" : '/auth/login';
  // const toUrl = '/auth/login';
  // window.location.href = window.location.hostname + toUrl;
  //   props.logout(props.history, '/');
  // };

  React.useEffect(async () => {
    const headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    const data = await Parse.queryData('treeData', { companyId: props.companyObj.objectId, type: 'webpage' }, { orderBy: 'orderId' })
    setData1(data)
    // const dad = data.filter(item => !item.parentId)
    // const son = data.filter(item => item.parentId)
    // console.log(dad, son)
    headroom.init();
    // const updateNavbarColor = () => {
    //   if (
    //     document.documentElement.scrollTop > 499 ||
    //     document.body.scrollTop > 499
    //   ) {
    //     setNavbarColor('');
    //   } else if (
    //     document.documentElement.scrollTop < 500 ||
    //     document.body.scrollTop < 500
    //   ) {
    //     // setNavbarColor('navbar-transparent');
    //     setNavbarColor('');
    //   }
    // };
    // window.addEventListener('scroll', updateNavbarColor);
    // return function cleanup() {
    //   window.removeEventListener('scroll', updateNavbarColor);
    // };
  }, []);
  // const data1 = webnavArr.filter(
  //   item =>
  //     item.level === '1' &&
  //     (item.pageType === '1' || item.pageType === '2' || item.pageType === '3'),
  // );

  const profile =
    (user.isAuthenticated && user.profile && user.profile.toJSON()) || {};
  const [name = '', host] = user.isAuthenticated
    ? profile.username && profile.username.split('@')
    : ['', ''];

  let showUser = true;
  if (companyObj.objectId === 'aKDM0xR8py' && !user.isAuthenticated) {
    showUser = false;
  }
  return (<>
    {bodyClick ? (
      <div
        id="bodyClick"
        onClick={() => {
          document.documentElement.classList.toggle('nav-open');
          setBodyClick(false);
          setCollapseOpen(false);
        }}
      />
    ) : null}
    <Navbar className={classnames('fixed-top', navbarColor)} expand="lg" id="navbar-main" style={{ position: 'sticky' }}>
      <Container>
        <div>
          <NavbarBrand id="navbar-brand" to="/" tag={Link}>{companyObj[dbLang]}</NavbarBrand>
          <UncontrolledTooltip placement="bottom" target="navbar-brand">
            {/* {intl.get('愛知国際学院')} */}
            {companyObj[dbLang]}
          </UncontrolledTooltip>
          <button
            className="navbar-toggler"
            id="navigation"
            type="button"
            onClick={() => {
              document.documentElement.classList.toggle('nav-open');
              setBodyClick(true);
              setCollapseOpen(true);
            }}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse navbar isOpen={collapseOpen}>
          {companyObj.useTemple ?
            <Nav className="mr-auto" navbar>
              <NavItem>
                <Link
                  className="nav-link"
                  to={`/blog`}
                  tag={Link}
                >
                  最新消息
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  to={`/blog`}
                  tag={Link}
                >
                  關於我們
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  to={`/donate`}
                  tag={Link}
                >
                  線上點燈
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  to={`/gallery`}
                  tag={Link}
                >
                  信眾活動
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  to={`/contact`}
                  tag={Link}
                >
                  交通資訊
                </Link>
              </NavItem>
            </Nav> : null}
          <Nav className="ml-auto" navbar>
            {data1
              .filter(item => !item.parentId)
              .map(item =>
                data1.find(child => child.parentId === item.objectId) ?
                  <UncontrolledDropdown nav inNavbar key={item.objectId}>
                    <DropdownToggle color="default" nav caret>
                      {item.title}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger" right>
                      {data1
                        .filter(child => child.level === 2 && child.parentId === item.objectId)
                        .map(element => (
                          <DropdownItem
                            key={element.objectId}
                            id={`b${element.objectId}`}
                            // to={`${item.pageType === '3' ? '/#' : '/page/'
                            //   }${element.path2 || element.objectId}`}
                            tag={Link}
                          >
                            {element.title}
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  :
                  <NavItem key={item.objectId}>
                    <Link
                      id={`a${item.objectId}`}
                      className="nav-link"
                      to={`/${item.href || ''}`}
                      // to={`/page/${item.path1 || item.objectId}`}
                      tag={Link}
                    >
                      {item.title}
                    </Link>
                  </NavItem>
              )
            }
            {companyObj.isMultiLang ? <DropdownLang1 {...props} /> : null}
            {showUser ? (
              <>
                {/* <Button color='blue' basic inverted style={{ margin: '15px' }} onClick={() => history.push('/register')}>人臉機註冊入口</Button> */}
                {/* <Button color='blue' basic inverted style={{ margin: '15px' }} as='a' href='/register'>人臉辨識機註冊入口</Button> */}
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle caret color="default" nav>
                    <i className="fa fa-user-o" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="dropdown-danger" right     >
                    <DropdownItem header>{name}</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem to="/profile" tag={Link}>
                      個人資料
                    </DropdownItem>
                    <DropdownItem to="/front/startUpEdit" tag={Link}>
                      網站設計
                    </DropdownItem>
                    {companyObj.isMultiLang ? (<DropdownItem to="/localeManage" tag={Link}>多國語系</DropdownItem>) : null}
                    <DropdownItem href="/admin">後台管理</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="#" onClick={logout}>登出</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>) : null}
            {/* {hostname === 'localhost' ? (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle caret color="default" nav>
                  <i className="fa fa-desktop" />
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="dropdown-danger" right >
                  {companies.length &&
                    companies.filter(item => item.useOfficial).map(({ name, objectId }) => (
                      <DropdownItem
                        key={`ab${objectId}`}
                        onClick={e => {
                          e.preventDefault();
                          changeCompany(objectId);
                          history.push('/');
                        }}
                     >
                        {name}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : null} */}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  </>);
};

// export default ColorNavbar;
const mapState = state => ({
  // availableCompanies: state.runtime.availableCompanies,
  user: state.user,
  // currentCompany: state.comp.companyId || '',
});
const mapDispatch = {
  setCompany,
  logout,
};
// export default withCookies(ColorNavbar);
export default connect(mapState, mapDispatch)(ColorNavbar);
