import React from 'react';
// react plugin used to create DropdownMenu for selecting items
import Select from 'react-select';

// reactstrap components
import { FormGroup, Container, Row, Col } from 'reactstrap';
import { Header, Icon, Button } from 'semantic-ui-react'

function FooterAboutUs(props) {
  const { footerObj = {} } = props
  const { phoneChecked, phone, faxChecked, fax, emailChecked, email, addrChecked, addr, timeChecked, time, list1Checked, list2Checked, } = footerObj
  const { lineChecked, facebookChecked, youtubeChecked, instagramChecked, line, facebook, instagram, youtube } = footerObj
  const [languageSelect, setLanguageSelect] = React.useState({
    value: 'en',
    label: 'English',
  });
  const [curencySelect, setCurencySelect] = React.useState({
    value: 'USD',
    label: 'USD',
  });
  return (
    <>
      <footer className="footer footer-big footer-gray">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="4" sm="6" xs="12">
              <Header>聯絡我們</Header>
              {!phoneChecked ? null : <h6><Icon name='phone' />&nbsp;{phone}</h6>}
              {!faxChecked ? null : <h6><Icon name='fax' />&nbsp;{fax}</h6>}
              {!emailChecked ? null : <h6><Icon name='mail' />&nbsp;{email}</h6>}
              {!addrChecked ? null : <h6><Icon name='map marker alternate' />&nbsp;{addr}</h6>}
              {!timeChecked ? null : <h6><Icon name='clock' />&nbsp;{time}</h6>}
            </Col>
            <Col className="ml-auto mr-auto" md="2" sm="6" xs="12">
              {!list1Checked ? null
                :
                <>
                  <Header>連結列表一</Header>
                  <h6>test1</h6>
                  <h6>test2</h6>
                  <h6>test3</h6>
                  <h6>test4</h6>
                </>}
            </Col>
            <Col className="ml-auto mr-auto" md="2" sm="6" xs="12">
              {!list2Checked ?
                null
                :
                <><Header>連結列表二</Header>
                  <h6>test1</h6>
                  <h6>test2</h6>
                  <h6>test3</h6>
                  <h6>test4</h6></>}
            </Col>
            <Col className="ml-auto mr-auto" md="4" sm="6" xs="12">
              {/* <Header>連結圖片</Header> */}
              {!lineChecked ? null : <Button icon='linechat' href={line} circular size='big' color='green' />}
              {!facebookChecked ? null : <Button icon='facebook' href={facebook} circular size='big' color='facebook' />}
              {!youtubeChecked ? null : <Button icon='youtube' href={youtube} circular size='big' color='youtube' />}
              {!instagramChecked ? null : <Button icon='instagram' href={instagram} circular size='big' color='instagram' />}
              {/* <h6>abcdefg12345</h6>
              <h6>abcdefg12345</h6>
              <h6>abcdefg12345</h6>
              <h6>abcdefg12345</h6> */}
            </Col>
            {/* <Col className="ml-auto mr-auto" md="4" sm="9" xs="12">
              <Row>
                <Col md="3" sm="3" xs="6">
                  <div className="links">
                    <ul className="uppercase-links stacked-links">
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          Discover
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          Blog
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="3" sm="3" xs="6">
                  <div className="links">
                    <ul className="uppercase-links stacked-links">
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          Contact Us
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          We're Hiring
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          About Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="3" sm="3" xs="6">
                  <div className="links">
                    <ul className="uppercase-links stacked-links">
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          Portfolio
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          How it works
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          Testimonials
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="3" xs="6">
                  <div className="social-area">
                    <Button
                      className="btn-just-icon btn-round mr-1"
                      color="facebook"
                   >
                      <i className="fa fa-facebook" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-round mr-1"
                      color="twitter"
                   >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-round mr-1"
                      color="google"
                   >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-round"
                      color="pinterest"
                   >
                      <i className="fa fa-pinterest-p" />
                    </Button>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="copyright">
                <div className="pull-left">
                  © {new Date().getFullYear()} Infowin Technology Co., Ltd., made with love
                </div>
                <div className="links pull-right">
                  <ul>
                    <li className="mr-1">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        Company Policy
                      </a>
                    </li>
                    |{' '}
                    <li className="mr-1">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        Terms
                      </a>
                    </li>
                    |{' '}
                    <li>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        Privacy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterAboutUs;
