

import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import PropTypes from 'prop-types';
// import withStyles from 'isomorphic-style-loader/withStyles';

// external-global styles must be imported in your JS.
import p from "bootstrap/dist/css/bootstrap.css";
// import p from '../../../public/assets_front/css/bootstrap.min.css';
import q from '../../../public/assets_front/css/paper-kit.css';
import r from '../../../public/assets_front/demo/demo.css';
import s from '../../../public/assets_front/demo/react-demo.css';
import u from '../../../public/assets_front/css/startup.css';

// import ai from '../../../public/css/aichi-test.css';
// import sl from "slick-carousel/slick/slick.css";
// import slt from "slick-carousel/slick/slick-theme.css";
// import rac from 'react-alice-carousel/lib/alice-carousel.css';
import gfont from '../../../public/css/gfont.css';
import ColorNavbar from './ColorNavbar.js';
import FooterAboutUs2 from './FooterAboutUs2';
import rsuite from 'rsuite/dist/rsuite.min.css';

export default function OfficialLayout(props) {
  // useStyles(p, q, r, s, u, rac, sl, slt, gfont, rsuite);
  useStyles(u, p, q, r, s, gfont, rsuite);

  return <>
    <ColorNavbar {...props} />
    {props.children}
    <FooterAboutUs2 />
  </>;
}

OfficialLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
